import { IPaymentIntent } from '../../model/payment_intent';
import { Compass } from '../../core/compass';
import { Payable } from '../../model/payable';
import { CompassError, ErrorCode } from '../../model/compass_error';
import { IWeb3Client } from '../../web3/web3_client';
import { Token } from '../../model/token';
import { TokenTxPaymentIntent } from '../../model/intents/token_tx_payment_intent';
import { DropletPaymentIntent } from '../../model/intents/droplet_payment_intent';
import { DeckPaymentIntent } from '../../model/intents/deck_payment_intent';
import { DeckClaimIntent } from '../../model/intents/deck_claim_intent';
import { StatusType } from '../../model/status';
import { ICompassFormViewInput } from './view_input';
import { ICompassFormViewOutput } from './view_output';
import { explorerUrl } from '../../util/chain_utils';
import { CompassAPI } from '../../core/compass_api';
import { ICompassStatusViewInput } from '../status/view_input';
import { Chain } from '../../model/chain';
import { ICompassStatusViewOutput } from '../status/view_output';
import Bugsnag from '@bugsnag/js';
import { floatModulo } from '../../../../lib/math';
import { ICompassPayableFormViewInput } from './payable_form_view_input';
import { FunctionName } from '../../model/payment_method';
import { PreparedTransaction } from '../../model/prepared_transaction';
import { resolvePromiseWithTimeout } from '../../../../lib/utils';
import { bugsnagReport } from '../../util/bugsnag_reporter';
import { isMobile } from '../../../../lib/mobile';
import { ICompassPayableFormViewOutput } from './payable_form_view_output';
import { floorAmount } from '../../util/amount_utils';
import { formatUnits, parseUnits } from 'viem';

enum ButtonAction {
  CreatePaymentIntent = 0,
  ApproveAllowance = 1,
}

enum Status {
  Ready,
  WrongDomain,
  WalletNotConnected,
  UnsupportedChain,
  EmptyAmount,
  WrongAmount,
  InsufficientAllowance,
  UnknownBalance,
  InsufficientBalance,
  PayableError,
  ForbiddenFromAddress,
  ChainWithoutDropletAddressIdentifier,
}

export enum SecondaryButtonAction {
  Back,
  Close,
}

export interface ICompassFormRouter {
  goToPaymentIntentConfirmation(paymentIntent: IPaymentIntent): Promise<void>;
  goBack(): void;
}

type CompassFormPresenterParams = {
  compassBaseUrl: string;
  payable: Payable;
  amount?: number;
  view: ICompassFormViewInput;
  payableFormView?: ICompassPayableFormViewInput;
  statusView: ICompassStatusViewInput;
  secondaryButtonAction?: SecondaryButtonAction;
  router: ICompassFormRouter;
};

export class CompassFormPresenter
  implements
    ICompassFormViewOutput,
    ICompassPayableFormViewOutput,
    ICompassStatusViewOutput
{
  private readonly compassBaseUrl: string;
  private addressIdentifier?: string;
  private web3Initialized: boolean = false;
  private selectedChain?: Chain;
  private selectedToken?: Token;
  private nextSelectedToken?: Token;
  private functionName: string = FunctionName.Transfer;
  /*
   * In those cases where the payable is already created (e.g. invoices), it will contain the GUID since the beginning.
   * Otherwise (e.g. contributions), it will be set when the user clicks the action button.
   */
  private payable: Payable;
  private paymentIntent: IPaymentIntent | undefined;
  private paymentIntentAmount: number | undefined;
  private allowance: bigint | undefined;
  private buttonAction: ButtonAction;
  private scheduledPreparingTakingTooLongMessageTimeout:
    | ReturnType<typeof setTimeout>
    | undefined;

  view: ICompassFormViewInput;
  payableFormView?: ICompassPayableFormViewInput;
  statusView: ICompassStatusViewInput;
  router: ICompassFormRouter;

  compass?: CompassAPI;

  constructor({
    compassBaseUrl,
    payable,
    amount,
    view,
    payableFormView,
    statusView,
    secondaryButtonAction,
    router,
  }: CompassFormPresenterParams) {
    this.compassBaseUrl = compassBaseUrl;

    this.view = view;
    this.payableFormView = payableFormView;
    this.statusView = statusView;
    this.router = router;

    this.payable = payable;
    if (amount) {
      this.paymentIntentAmount = amount;
    } else if (
      this.payable.minAmount &&
      this.payable.minAmount === this.payable.maxAmount
    ) {
      this.paymentIntentAmount = this.payable.minAmount;
      this.payableFormView?.fillAmount?.(this.paymentIntentAmount.toString());
    }

    switch (this.payable.acceptedPaymentMethod) {
      case 'droplet':
        this.functionName = FunctionName.PresailDistributeToken;
        this.buttonAction = ButtonAction.ApproveAllowance;
        break;
      case 'deck':
        this.functionName = FunctionName.PresailDeckLockTokens;
        this.buttonAction = ButtonAction.ApproveAllowance;
        break;
      case 'claim':
        this.functionName = FunctionName.PresailDeckClaimTokens;
        this.buttonAction = ButtonAction.CreatePaymentIntent;
        break;
      case 'transfer':
        this.functionName = FunctionName.Transfer;
        this.buttonAction = ButtonAction.CreatePaymentIntent;
        break;
      default:
        this.functionName = FunctionName.Transfer;
        this.buttonAction = ButtonAction.CreatePaymentIntent;
        break;
    }

    if (this.payable.showTokenSelector) {
      this.view.showTokenSelector?.();
    } else {
      this.view.hideTokenSelector?.();
    }

    if (secondaryButtonAction === SecondaryButtonAction.Back) {
      this.view.showBackButton?.();
      this.view.hideCancelButton?.();
    } else {
      this.view.hideBackButton?.();
      this.view.showCancelButton?.();
    }
  }

  /*
   * IViewOutput implementation
   */
  async onWeb3Connect(web3Client: IWeb3Client): Promise<void> {
    this.compass = new Compass({
      web3Client: web3Client,
      compassBaseUrl: this.compassBaseUrl,
    });
    await this.initializeWeb3();
  }

  async initializeWeb3() {
    this.addressIdentifier = await this.compass!.getAddressIdentifier();
    if (!this.addressIdentifier) {
      this.updateStatusInView();
      return;
    }
    delete this.selectedToken;
    this.disableActionButtonInView();
    await this.getTokenBalances();
    this.view.showTokenBalances(
      this.payable.chainsAndTokens?.flatMap((chain) => chain.tokens!) ?? []
    );
    this.statusView.showStatus({
      title: 'Loading wallet info...',
      statusType: StatusType.loading,
      message: '...',
    });
    this.web3Initialized = true;
  }

  async onWeb3ChainChange(chain: Chain): Promise<void> {
    if (chain.id === undefined) return;

    this.selectedChain = chain;
    const isSelectedChainSupportedByPayable =
      this.payable.chainsAndTokens?.some(
        (supportedChain) => supportedChain.id === chain.id
      ) ?? false;
    const currentAddressIdentifier = await this.compass!.getAddressIdentifier();
    if (
      !this.web3Initialized ||
      this.addressIdentifier === undefined ||
      this.addressIdentifier !== currentAddressIdentifier
    ) {
      await this.initializeWeb3();
    }
    if (this.compass) {
      const isSelectedChainSupportedByCompass =
        await this.compass?.supportsChain(chain.id);
      if (
        isSelectedChainSupportedByCompass &&
        isSelectedChainSupportedByPayable
      ) {
        if (
          this.nextSelectedToken &&
          this.nextSelectedToken.chain.id === chain.id
        ) {
          await this.onSelectedTokenChange(this.nextSelectedToken.id);
          delete this.nextSelectedToken;
        } else {
          const defaultSelectedToken = this.payable.chainsAndTokens!.find(
            (supportedChain) => supportedChain.id === chain.id
          )!.tokens![0];
          await this.onSelectedTokenChange(defaultSelectedToken.id);
        }
        if (!this.payable.showTokenSelector) this.view.hideTokenSelector?.();
      } else {
        delete this.selectedToken;
        this.view.showConnectedToUnsupportedChain();
        this.updateStatusInView();
      }
    }
  }

  async onSelectedTokenChange(tokenId: string): Promise<void> {
    const token = this.payable.chainsAndTokens
      ?.flatMap((chain) => chain.tokens)
      .find((token) => token!.id.toString() === tokenId.toString())!;

    if (token.chain.id !== this.selectedChain?.id) {
      this.nextSelectedToken = token;
      await this.switchChain(token.chain);
      return;
    }

    this.disableActionButtonInView();
    this.setActionButtonTextInView();
    this.selectedToken = token;

    // Get the max between the tokenBalance and the max amount of the payable
    let maxAmount = Math.min(
      this.payable.maxAmount ?? Infinity,
      this.selectedToken.balance ?? Infinity
    );

    if (this.payable.incrementAmount) {
      // Given maxAmount, returns the max number less than that one that is divisible by incrementAmount
      maxAmount =
        Math.floor(maxAmount / this.payable.incrementAmount) *
        this.payable.incrementAmount;
    }

    this.refreshPaymentIntent();

    if (maxAmount != undefined && maxAmount != Infinity && maxAmount != 0) {
      const maxDecimals = this.paymentIntent?.paymentMethod.token.native
        ? 4
        : 0;
      const flooredMaxAmount = floorAmount(maxAmount, maxDecimals);
      this.payableFormView?.showMaxAmount(flooredMaxAmount.toString());
    } else {
      this.payableFormView?.hideMaxAmount();
    }

    // Allowance approval is only required for non-native droplets
    if (
      ['droplet', 'deck'].includes(this.payable.acceptedPaymentMethod) &&
      this.paymentIntent &&
      !this.paymentIntent.paymentMethod.token.native &&
      this.paymentIntent.paymentMethod.token.chain.dropletAddressIdentifier
    ) {
      this.allowance = await this.compass!.fetchAllowance({
        paymentIntent: this.paymentIntent as DropletPaymentIntent,
      });
      await this.refreshPreparedApproveAllowanceTransaction();
    }
    if (this.paymentIntent?.paymentMethod.token.native) {
      this.payableFormView?.hideSelectedTokenTicker();
    } else {
      this.payableFormView?.showSelectedTokenTicker();
    }
    this.view.showSelectedToken(token);
    this.updateStatusInView();
  }

  async onAmountChange(amount: number): Promise<void> {
    if (
      this.payable.modelName === 'ContributionIntent' &&
      this.paymentIntent instanceof DropletPaymentIntent
    ) {
      const commissionAmount =
        (amount * this.payable.platformCommissionPercent!) / 100;

      this.payable.transfers = [
        {
          amount: amount - commissionAmount,
          toAddressIdentifier: this.payable.contributionAddress!,
        },
      ];

      // Add the commission transfer only if commissionAmount is greater than 0
      if (commissionAmount > 0) {
        this.payable.transfers.push({
          amount: commissionAmount,
          toAddressIdentifier: this.payable.platformCommissionAddress!,
        });
      }
    }
    this.paymentIntentAmount = amount;
    this.refreshPaymentIntent();
    await this.refreshPreparedApproveAllowanceTransaction();
    if (this.selectedToken == null) return;

    this.updateStatusInView();
  }

  async onMinMaxAmountsChange(
    minAmount: number,
    maxAmount: number
  ): Promise<void> {
    this.payable.minAmount = minAmount;
    this.payable.maxAmount = maxAmount;
    this.refreshPaymentIntent();
    await this.refreshPreparedApproveAllowanceTransaction();
    await this.onSelectedTokenChange(this.selectedToken!.id);
    this.updateStatusInView();
  }

  async onActionButtonClick(): Promise<void> {
    const status = this.getStatus();
    if (status == Status.Ready || status == Status.InsufficientAllowance) {
      if (this.payableFormView?.createPayable) {
        this.view.disableForm();
        this.view.hideTaxedTokenView?.();
        this.statusView.showStatus({
          title: 'Preparing payment...',
          statusType: StatusType.loading,
          message: '...',
        });
        this.schedulePreparingTakingTooLongMessage(5);
        const success = await this.payableFormView.createPayable();
        this.cancelScheduledPreparingTakingTooLongMessage();
        if (!success) {
          this.view.enableForm();
          this.statusView.showStatus({
            title: 'Could not prepare the payment. Please retry',
            statusType: StatusType.warning,
            message:
              'We are experiencing high demand at the moment. Please refresh the page and try again.',
          });
          return;
        }
      }
      switch (this.buttonAction) {
        case ButtonAction.ApproveAllowance:
          return await this.approveAllowance();
        case ButtonAction.CreatePaymentIntent:
          switch (this.payable.acceptedPaymentMethod) {
            case 'droplet':
              if (this.paymentIntent!.paymentMethod.token.native) {
                this.functionName = FunctionName.PresailDistribute;
              } else {
                this.functionName = FunctionName.PresailDistributeToken;
              }
              break;
            case 'deck':
              this.functionName = FunctionName.PresailDeckLockTokens;
              break;
            case 'claim':
              this.functionName = FunctionName.PresailDeckClaimTokens;
              break;
            case 'transfer':
              this.functionName = FunctionName.Transfer;
              break;
            default:
              return;
          }
          this.paymentIntent!.paymentMethod.functionName = this.functionName;
          return this.submitPaymentIntent();
      }
    } else {
      this.updateStatusInView();
    }
  }

  async onTaxedTokenActionButtonClick(): Promise<void> {
    this.functionName = FunctionName.PresailDistributeTokenSimple;
    this.paymentIntent!.paymentMethod.functionName = this.functionName;
    return this.submitPaymentIntent();
  }

  onBackButtonClick(): void {
    this.statusView.hideStatus();
    this.router.goBack();
  }

  /*
   * IStatusViewOutput implementation
   */
  onCompassStatusViewInputUpdated(statusView: ICompassStatusViewInput): void {
    this.statusView = statusView;
  }

  /*
   * Private methods
   */
  private async getTokenBalances(): Promise<void> {
    await Promise.all(
      this.payable.chainsAndTokens!.map(async (chain) => {
        await Promise.all(
          chain.tokens!.map(async (token) => {
            const balance = await this.getTokenBalance(token);
            if (balance !== undefined) {
              const maxDecimals = token.addressIdentifier === '' ? 4 : 2;
              token.balance = balance;
              token.displayBalance = floorAmount(
                balance,
                maxDecimals
              ).toString();
            }
          })
        );
      })
    );
  }

  private async getTokenBalance(token: Token): Promise<number | undefined> {
    if (!this.addressIdentifier) return;

    try {
      return await this.compass?.getBalance(
        this.addressIdentifier!,
        token.addressIdentifier,
        token.chain.id
      );
    } catch (error) {
      console.error(error);
    }
  }

  private async switchChain(chain: Chain): Promise<void> {
    if (chain.id == this.selectedChain?.id) return;

    if (!this.payable.showTokenSelector) this.view.hideTokenSelector?.();

    this.statusView.showStatus({
      title: `Switching to ${chain.name}...`,
      statusType: StatusType.loading,
      message: 'Confirm the network switch in your wallet.',
    });
    this.disableActionButtonInView();
    if (await this.compass?.supportsChain(chain.id)) {
      try {
        await this.compass?.switchChain(chain.id);
      } catch (error) {
        if (error instanceof CompassError) {
          switch (error.code) {
            case ErrorCode.UnsupportedChainError: {
              this.updateStatusInView();
              this.statusView.showStatus({
                title: `Could not switch to ${chain.name}`,
                statusType: StatusType.warning,
                message: `The chain ${chain.name} is not supported by your wallet. Please select a supported network.`,
              });
              return;
            }
            case ErrorCode.CannotSetDefaultChainError:
            case ErrorCode.GenericSwitchChainError: {
              this.updateStatusInView();
              if (isMobile()) {
                this.statusView.showStatus({
                  title: `Could not switch to ${chain.name}`,
                  statusType: StatusType.warning,
                  message: `The wallet rejected switching networks. Make sure there is no other switch-network request pending. Please also note that some wallet apps don't play well with some web browsers; make sure you use the built-in in-app browser of your wallet app.`,
                });
              } else {
                this.statusView.showStatus({
                  title: `Could not switch to ${chain.name}`,
                  statusType: StatusType.warning,
                  message: `The wallet rejected switching networks. Make sure there is no other switch-network request pending.`,
                });
              }
              return;
            }
          }
        }
        this.updateStatusInView();
      }
    } else {
      this.updateStatusInView();
    }
  }

  private async approveAllowance(): Promise<void> {
    this.view.disableForm();
    this.statusView.showStatus({
      title: 'Approve the allowance',
      statusType: StatusType.loading,
      message: `Please confirm the allowance approval for ${
        this.paymentIntent!.amount
      } ${this.paymentIntent?.paymentMethod.token.ticker} in your wallet.`,
    });
    this.view.hideTaxedTokenView?.();

    try {
      await this.compass?.approveAllowance(
        {
          preparedTransaction:
            this.paymentIntent?.preparedAllowanceApprovalTransaction!,
        },
        {
          onTransactionSubmitted: (transactionHash: string) => {
            this.statusView.showStatus({
              title: 'Approving allowance',
              statusType: StatusType.loading,
              message: `Allowance approval submitted. Waiting for confirmation...`,
              linkUrl: explorerUrl(
                this.paymentIntent!.paymentMethod.token.chain,
                transactionHash
              ),
              linkText: 'View TX',
            });
          },
        }
      );
      // Check allowance again, since the user might have set a different amount
      this.allowance = await this.compass!.fetchAllowance({
        paymentIntent: this.paymentIntent as DropletPaymentIntent,
      });
      await this.refreshPreparedApproveAllowanceTransaction();

      if (!this.hasEnoughAllowance()) {
        // Provide specific contextual message, since the user has actually approved the allowance.
        this.statusView.showStatus({
          title: 'Insufficient allowance',
          statusType: StatusType.warning,
          message: `The allowance was approved, but it is still insufficient (${formatUnits(
            this.allowance,
            this.paymentIntent!.paymentMethod.token.decimals
          )} ${
            this.paymentIntent!.paymentMethod.token.ticker
          }). Please allow for as much as ${this.paymentIntent!.amount} ${
            this.paymentIntent!.paymentMethod.token.ticker
          }.`,
        });
        this.view.enableForm();
        return;
      }
      this.updateStatusInView();
    } catch (error) {
      console.log('[COMPASS] Error', error);
      if (error instanceof CompassError) {
        switch (error.code) {
          case ErrorCode.UserRejectedTransactionError: {
            this.statusView.showStatus({
              title: 'Approval rejected',
              statusType: StatusType.warning,
              message: `The approval was rejected in the wallet. Please allow the Presail Smart Contract to spend ${
                this.paymentIntent!.amount
              } ${this.paymentIntent?.paymentMethod.token.ticker}.`,
            });
            this.view.enableForm();
            return;
          }
        }
      }
      const errorMessage =
        error instanceof CompassError
          ? error.userFriendlyMessage
          : "Your wallet encountered an 'internal error' likely due to a connectivity issue with the wallet, blockchain, or smart contract. Please ensure everything is set up correctly and try again. If the error persists, contact support for help.";
      this.statusView.showStatus({
        title: "We couldn't complete your approval",
        statusType: StatusType.error,
        message: errorMessage,
      });
      this.view.enableForm();
      Bugsnag.notify(error as Error);
    }
  }

  private async submitPaymentIntent(): Promise<void> {
    if (!this.compass) return;
    if (!this.paymentIntent) return;

    // Cache payable to check if it's the same when the payment intent is received
    const payable = { ...this.payable };
    this.payable = payable;
    this.view.disableForm();
    this.view.hideTaxedTokenView?.();
    this.statusView.showStatus({
      title: 'Preparing payment...',
      statusType: StatusType.loading,
      message: '...',
    });
    this.schedulePreparingTakingTooLongMessage(5);

    try {
      // TX can be prepared in the same action since this one doesn't hit the wallet
      const preparedTransaction = (await resolvePromiseWithTimeout(
        this.compass.getPreparedTransaction(this.paymentIntent),
        20
      )) as PreparedTransaction | null;
      this.cancelScheduledPreparingTakingTooLongMessage();
      if (preparedTransaction) {
        this.paymentIntent.preparedTransaction = preparedTransaction;
        const guid = await this.compass.createPaymentIntent({
          paymentIntent: this.paymentIntent,
        });
        if (guid === undefined) {
          this.statusView.showStatus({
            title: 'Could not prepare the payment. Please retry',
            statusType: StatusType.warning,
            message:
              'We are experiencing high demand at the moment. Please refresh the page and try again.',
          });
          return;
        }
        this.paymentIntent.guid = guid;
        if (payable != this.payable) {
          this.statusView.showStatus({
            title: 'Form updated while processing',
            statusType: StatusType.warning,
            message:
              'The form was updated while we were processing your request. Please try again.',
          });
          return;
        }
        this.statusView.hideStatus();
        await this.router.goToPaymentIntentConfirmation(this.paymentIntent!);
        this.view.enableForm();
      } else {
        this.statusView.showStatus({
          title: 'Could not make the contribution',
          statusType: StatusType.error,
          message:
            "Please refresh the page and try again. If that doesn't help, try disabling other wallet extensions, as some of them may conflict with each other. If the problem persists, please contact an admin.",
        });
        this.view.disableForm();
        bugsnagReport({
          message:
            'Preparing payment timeout: getPreparedTransaction took too long',
          paymentIntent: this.paymentIntent,
          payable: this.payable,
          web3ConfigInfo: this.compass.getWeb3ConfigInfo(),
        });
        return;
      }
    } catch (error) {
      this.cancelScheduledPreparingTakingTooLongMessage();
      if (error instanceof CompassError) {
        switch (error.code) {
          case ErrorCode.BackendApiError: {
            if (error.payable) {
              this.payable = error.payable;
              this.refreshPaymentIntent();
              await this.refreshPreparedApproveAllowanceTransaction();
              this.updateStatusInView();
            } else {
              this.view.enableForm();
            }
            this.statusView.showStatus({
              title: 'Could not prepare the payment.',
              statusType: StatusType.error,
              message: error.userFriendlyMessage,
            });

            if (error.canRetryIn != undefined) {
              setTimeout(() => {
                this.view.enableForm();
              }, new Date(error.canRetryIn).getTime() - Date.now());
            }

            return;
          }
          case ErrorCode.InsufficientAllowanceError:
            // Check allowance again, since the user might have set a different amount
            this.allowance = await this.compass!.fetchAllowance({
              paymentIntent: this.paymentIntent as DropletPaymentIntent,
            });
            await this.refreshPreparedApproveAllowanceTransaction();
            this.updateStatusInView();
            return;
          case ErrorCode.InsufficientBalanceError:
            this.selectedToken!.balance = await this.getTokenBalance(
              this.selectedToken!
            );
            if (
              this.paymentIntent instanceof DropletPaymentIntent &&
              this.paymentIntent.amount &&
              this.selectedToken?.balance &&
              this.selectedToken?.balance >= this.paymentIntent.amount
            ) {
              // If the user has balance, it might mean that the token is taxed or burned.
              this.statusView.showStatus({
                title: 'Token tax/fee detected',
                statusType: StatusType.error,
                message: `This token contract has a built-in transaction fee. Therefore, recipients may receive fewer tokens than you send. If you know how this mechanism works, proceed to payment. If not, reach out to the token contract owner. They may also be able to whitelist Presail's smart contract. It's address is: ${this.paymentIntent.contractAddressIdentifier}`,
              });
              this.view.enableForm();
              this.view.showTaxedTokenView?.();
            } else {
              this.updateStatusInView();
            }
            return;
          case ErrorCode.InsufficientBalanceForFeesError:
            this.statusView.showStatus({
              title: 'Insufficient balance',
              statusType: StatusType.error,
              message:
                'The total cost of the transaction, including fees, exceeds your current balance.',
            });
            this.view.enableForm();
            return;
          case ErrorCode.ContractExecutionTakingTooLongError:
            this.statusView.showStatus({
              title: 'We could not prepare the payment',
              statusType: StatusType.error,
              message:
                'It took too long to get a response from the blockchain nodes. This might be due to network issues. Please make sure your connection is stable and try again.',
            });
            this.view.enableForm();
            return;
          case ErrorCode.NoCSRFTokenError:
            this.statusView.showStatus({
              title: 'Could not prepare the payment',
              statusType: StatusType.error,
              message:
                "Please refresh the page and retry. If that doesn't help, try disabling other wallet extensions, as some of them may conflict with each other. If the problem persists, please contact an admin.",
            });
            this.view.disableForm();
            Bugsnag.notify(error as Error);
            return;
          case ErrorCode.DeckDistributionInvalidated:
            this.statusView.showStatus({
              title: 'We could not prepare the distribution claiming',
              statusType: StatusType.error,
              message:
                'The distribution is currently disabled for claiming. Please refresh the page and try again later.',
            });
            this.view.enableForm();
            return;
          case ErrorCode.DeckDistributionAlreadyClaimed:
            this.statusView.showStatus({
              title: 'We could not prepare the distribution claiming',
              statusType: StatusType.error,
              message:
                'The distribution has already been claimed by your account.',
            });
            this.view.enableForm();
            return;
          case ErrorCode.DeckDistributionForceNonExact:
            this.statusView.showStatus({
              title: 'Token tax/fee detected',
              statusType: StatusType.error,
              message:
                'This distribution method does not support tokens that have a built-in transaction fee.',
            });
            this.view.enableForm();
            return;
          default:
            this.statusView.showStatus({
              title: 'Could not prepare the payment',
              statusType: StatusType.error,
              message:
                "Please refresh the page and try again. If that doesn't help, try disabling other wallet extensions, as some of them may conflict with each other. If the problem persists, please contact an admin.",
            });
            this.view.disableForm();
            Bugsnag.notify(error as Error);
            return;
        }
      } else {
        this.statusView.showStatus({
          title: "We couldn't prepare your transaction",
          statusType: StatusType.error,
          message:
            "Your wallet encountered an 'internal error' likely due to a connectivity issue with the wallet, blockchain, or smart contract. Please ensure everything is set up correctly and try again. If the error persists, contact support for help.",
        });
        this.buttonAction = ButtonAction.CreatePaymentIntent;
        this.setActionButtonTextInView();
        this.view.enableForm();
        Bugsnag.notify(error as Error);
      }
    }
  }

  private updateStatusInView(): void {
    if (!this.compass) return;

    switch (this.getStatus()) {
      case Status.WrongDomain:
        const correctLinkURL = new URL(
          this.compassBaseUrl +
            window.location.pathname +
            window.location.search
        );
        this.statusView.showStatus({
          title: 'Invalid contribution link',
          statusType: StatusType.error,
          message: `You are trying to contribute using an invalid link. Please make sure you invest via ${correctLinkURL.host}.`,
          linkText: 'Go to deal',
          linkUrl: correctLinkURL,
        });
        this.disableActionButtonInView();
        break;
      case Status.WalletNotConnected:
        this.statusView.showStatus({
          title: 'Wallet not connected',
          statusType: StatusType.error,
          message:
            'Wallet disconnected. Please connect your wallet and try again.',
        });
        this.view.disableForm();
        break;
      case Status.UnsupportedChain:
        this.statusView.showStatus({
          title: 'Unsupported network',
          statusType: StatusType.error,
          message: this.unsupportedChainErrorMessage(this.selectedChain),
        });
        this.view.enableForm();
        this.setActionButtonTextInView('Unsupported network');
        this.disableActionButtonInView();
        this.view.showTokenSelector?.();
        break;
      case Status.PayableError:
        this.statusView.showStatus({
          title: 'An issue prevented you from contributing',
          statusType: StatusType.error,
          message: this.payable.error,
        });
        this.view.enableForm();
        this.disableActionButtonInView();
        break;
      case Status.ChainWithoutDropletAddressIdentifier:
        this.statusView.showStatus({
          title: 'Droplet not configured for this chain',
          statusType: StatusType.error,
          message: 'Please contact an admin',
        });
        this.view.disableForm();
        this.disableActionButtonInView();
        break;
      case Status.ForbiddenFromAddress:
        this.statusView.showStatus({
          title: 'Payments from this wallet are not allowed',
          statusType: StatusType.error,
          message: 'Please connect a valid wallet and try again.',
        });
        this.view.enableForm();
        this.disableActionButtonInView();
        break;
      case Status.EmptyAmount:
        this.view.enableForm();
        this.disableActionButtonInView();
        this.statusView.hideStatus();
        this.payableFormView?.showEmptyAmountIndicator();
        break;
      case Status.WrongAmount:
        this.view.enableForm();
        this.disableActionButtonInView();
        this.statusView.hideStatus();
        if (this.payableFormView) {
          this.payableFormView.showInvalidAmountIndicator(
            this.getAmountValidationError()
          );
        } else {
          this.statusView.showStatus({
            title: 'Invalid amount',
            statusType: StatusType.error,
            message: this.getAmountValidationError(),
          });
        }
        break;
      case Status.InsufficientAllowance:
        this.statusView.showStatus({
          title: 'Allowance approval needed',
          statusType: StatusType.warning,
          message: `You have to allow the Presail Smart Contract to spend ${
            this.paymentIntent!.amount
          } ${this.paymentIntent?.paymentMethod.token.ticker}.`,
        });
        this.buttonAction = ButtonAction.ApproveAllowance;
        this.setActionButtonTextInView();
        this.view.enableForm();
        break;
      case Status.UnknownBalance:
        this.statusView.showStatus({
          title: 'Unknown balance',
          statusType: StatusType.warning,
          message: `Could not retrieve your ${
            this.paymentIntent?.paymentMethod.token.ticker
          } balance at the moment. Please make sure you have at least ${
            this.paymentIntent!.amount
          } ${
            this.paymentIntent?.paymentMethod.token.ticker
          } balance before continuing.`,
        });
        this.view.enableForm();
        this.payableFormView?.showValidAmountIndicator();
        break;
      case Status.InsufficientBalance:
        const message = `Please make sure you have at least ${
          this.paymentIntent!.amount
        } ${this.paymentIntent?.paymentMethod.token.ticker} before continuing.`;
        this.view.enableForm();
        this.disableActionButtonInView();
        this.statusView.hideStatus();
        if (this.payableFormView) {
          this.payableFormView.showInvalidAmountIndicator(message);
        } else {
          this.statusView.showStatus({
            title: 'Insufficient balance',
            statusType: StatusType.error,
            message: message,
          });
        }
        break;
      case Status.Ready:
        this.statusView.hideStatus();
        this.buttonAction = ButtonAction.CreatePaymentIntent;
        this.setActionButtonTextInView();
        this.view.enableForm();
        this.payableFormView?.showValidAmountIndicator();
        break;
    }
  }

  private isGlobalDomain(): boolean {
    return /app\..*(spring\.net|presail\.com|lvh\.me)/.test(
      window.location.hostname
    );
  }

  private isCorrectDomain(): boolean {
    const url = new URL(this.compassBaseUrl);
    return url.hostname === window.location.hostname || this.isGlobalDomain();
  }

  private getStatus(): Status {
    if (!this.isCorrectDomain()) {
      return Status.WrongDomain;
    }
    if (!this.isWalletConnected()) {
      return Status.WalletNotConnected;
    }
    if (this.selectedToken === undefined) {
      return Status.UnsupportedChain;
    }
    if (this.payable.error) {
      return Status.PayableError;
    }
    if (
      this.payable.allowedFromAddresses &&
      !this.payable.allowedFromAddresses.includes(this.addressIdentifier!)
    ) {
      return Status.ForbiddenFromAddress;
    }
    if (
      this.paymentIntent?.amount === undefined ||
      this.paymentIntent.amount === 0
    ) {
      return Status.EmptyAmount;
    }
    if (!this.isAmountValid()) {
      return Status.WrongAmount;
    }
    if (
      this.payable.acceptedPaymentMethod === 'droplet' &&
      !this.paymentIntent.paymentMethod.token.chain.dropletAddressIdentifier
    ) {
      return Status.ChainWithoutDropletAddressIdentifier;
    }
    // Allowance approval is only required for non-native droplets
    if (
      ['droplet', 'deck'].includes(this.payable.acceptedPaymentMethod) &&
      !this.paymentIntent.paymentMethod.token.native &&
      !this.hasEnoughAllowance()
    ) {
      return Status.InsufficientAllowance;
    }
    if (this.selectedToken.balance === undefined) {
      return Status.UnknownBalance;
    }
    // Having a token balance is only required for payments, not claiming.
    if (
      this.payable.acceptedPaymentMethod != 'claim' &&
      this.selectedToken.balance < this.paymentIntent!.amount!
    ) {
      return Status.InsufficientBalance;
    }
    return Status.Ready;
  }

  private isAmountValid(): boolean {
    return this.getAmountValidationError() === undefined;
  }

  private getAmountValidationError(): string | undefined {
    if (this.paymentIntent?.amount === undefined) return;

    if (
      this.payable.minAmount === this.payable.maxAmount &&
      this.payable.minAmount !== this.paymentIntent.amount
    ) {
      return `Amount must be exactly ${this.payable.minAmount} ${this.paymentIntent.paymentMethod.token.ticker}`;
    } else {
      if (
        this.payable.minAmount &&
        this.paymentIntent.amount < this.payable.minAmount
      ) {
        return `Amount must be at least ${this.payable.minAmount} ${this.paymentIntent.paymentMethod.token.ticker}`;
      }
      if (this.payable.maxAmount == 0) {
        return 'You cannot contribute more to this pool.';
      }
      if (
        this.payable.maxAmount &&
        this.paymentIntent.amount > this.payable.maxAmount
      ) {
        return `Amount must be at most ${this.payable.maxAmount} ${this.paymentIntent.paymentMethod.token.ticker}`;
      }
      if (
        this.payable.incrementAmount &&
        floatModulo(this.paymentIntent.amount, this.payable.incrementAmount) !==
          0
      ) {
        if (this.payable.incrementAmount == 1) {
          return 'Only whole numbers are accepted, without decimals';
          // Don't show when native raises don't have an increment amount (it defaults to 1e-18)
        } else if (this.payable.incrementAmount != 1e-18) {
          return `Amount must be a multiple of ${this.payable.incrementAmount} ${this.paymentIntent.paymentMethod.token.ticker}`;
        }
      }
    }
  }

  // Wallet state checks
  private isWalletConnected(): boolean {
    return (
      this.selectedChain !== undefined && this.addressIdentifier !== undefined
    );
  }

  private unsupportedChainErrorMessage(chain: Chain | undefined): string {
    if (chain) {
      return `Chain ${
        chain.name || chain.id
      } is not supported. Please select a supported network in the selector.`;
    } else {
      return `Unsupported network. Please select a supported network in the selector.`;
    }
  }

  private refreshPaymentIntent(): void {
    if (!this.addressIdentifier || !this.selectedToken) return;

    const paymentMethod = {
      addressIdentifier: this.addressIdentifier,
      token: this.selectedToken,
      functionName: this.functionName,
    };
    if (this.payable.acceptedPaymentMethod === 'droplet') {
      this.paymentIntent = new DropletPaymentIntent({
        payable: this.payable,
        amount: this.paymentIntentAmount,
        paymentMethod: paymentMethod,
      });
    } else if (this.payable.acceptedPaymentMethod === 'deck') {
      this.paymentIntent = new DeckPaymentIntent({
        payable: this.payable,
        amount: this.paymentIntentAmount,
        paymentMethod: paymentMethod,
      });
    } else if (this.payable.acceptedPaymentMethod === 'claim') {
      this.paymentIntent = new DeckClaimIntent({
        payable: this.payable,
        amount: this.paymentIntentAmount,
        paymentMethod: paymentMethod,
      });
    } else {
      this.paymentIntent = new TokenTxPaymentIntent({
        payable: this.payable,
        amount: this.paymentIntentAmount,
        paymentMethod: paymentMethod,
      });
    }
  }

  private async refreshPreparedApproveAllowanceTransaction(): Promise<void> {
    if (
      !['droplet', 'deck'].includes(this.payable.acceptedPaymentMethod) ||
      this.paymentIntent!.paymentMethod.token.native
    )
      return;

    if (this.paymentIntent?.amount && !this.hasEnoughAllowance()) {
      this.paymentIntent!.preparedAllowanceApprovalTransaction =
        await this.compass?.getPreparedAllowanceApproval({
          paymentIntent: this.paymentIntent as DropletPaymentIntent,
        });
    }
  }

  private hasEnoughAllowance(): boolean | undefined {
    if (
      !this.allowance ||
      !['droplet', 'deck'].includes(this.payable.acceptedPaymentMethod) ||
      this.paymentIntent!.paymentMethod.token.native ||
      !this.paymentIntent?.amount
    )
      return;

    return (
      this.allowance >=
      parseUnits(
        this.paymentIntent!.amount.toString(),
        this.paymentIntent!.paymentMethod.token.decimals
      )
    );
  }

  private schedulePreparingTakingTooLongMessage(afterSeconds: number): void {
    this.scheduledPreparingTakingTooLongMessageTimeout = setTimeout(() => {
      this.statusView.showStatus({
        title: 'Preparing payment...',
        statusType: StatusType.loading,
        message:
          'It is taking longer than expected to prepare the payment. Please be patient.',
      });
    }, afterSeconds * 1000);
  }

  private cancelScheduledPreparingTakingTooLongMessage(): void {
    clearTimeout(this.scheduledPreparingTakingTooLongMessageTimeout);
  }

  /*
   * Helpers to also determine which button text to show in the view
   */
  private disableActionButtonInView(): void {
    this.view.disableActionButton();
  }

  private setActionButtonTextInView(buttonText?: string): void {
    buttonText ||=
      this.buttonAction == ButtonAction.CreatePaymentIntent
        ? this.payable.ctaText || 'Proceed to payment...'
        : 'Approve';
    this.view.setActionButtonText(buttonText);
  }
}
