import Web3Controller from './web3_controller';
import { Web3Account } from '../web3/magnet/types';
import { MagnetUserRejectedRequestError } from '../web3/magnet/errors';
import { walletShortname } from '../helpers/wallet_helpers';
import { flash } from '../lib/flashes';
import Bugsnag from '@bugsnag/js';

export default class extends Web3Controller {
  static targets = [
    'address',
    'form',
    'publicKeyInput',
    'chainIdInput',
    'signatureInput',
    'templateInput',
    'signInButton',
  ];
  declare addressTarget: HTMLElement;
  declare formTarget: HTMLFormElement;
  declare publicKeyInputTarget: HTMLInputElement;
  declare chainIdInputTarget: HTMLInputElement;
  declare signatureInputTarget: HTMLInputElement;
  declare templateInputTarget: HTMLInputElement;
  declare signInButtonTarget: HTMLButtonElement;

  static values = {
    requestSignableMessageUrl: String,
  };
  declare requestSignableMessageUrlValue: string;

  isRetrievingSignableMessage: boolean = false;

  onAccountConnected(account: Web3Account) {
    super.onAccountConnected(account);
    this.addressTarget.textContent = walletShortname(account.address!);
    this.#enableSignInButton();
  }

  async onSignMessageButtonClick(event: Event) {
    if (event !== undefined) {
      event.preventDefault();
    }
    this.#setLoadingSignInButton('Sign the message in your wallet...');
    await this.signMessage(event);
  }

  async signMessage(event: Event) {
    const signableMessage = await this.#requestSignableMessage();

    if (signableMessage === undefined) {
      this.#enableSignInButton();
      return false;
    }

    // Ask the user to sign the message in their wallet
    try {
      const signedMessage = await this.magnet!.signMessage({
        message: signableMessage,
      });
      if (signedMessage !== undefined) {
        this.#setLoadingSignInButton('Signing in...');
        this.#filloutAndSubmitForm(
          this.magnet!.web3Account!.address!,
          signedMessage,
          this.magnet!.web3Account!.chain!.id.toString()
        );
      }
    } catch (error: any) {
      this.#enableSignInButton();
      if (error instanceof MagnetUserRejectedRequestError) {
        // Empty on purpose
      } else if (error.code == 4001) {
        await flash('Wallet closed');
      } else {
        await flash('Invalid signature');
        Bugsnag.notify(`Undefined signed message: ${error.message}`);
        throw error;
      }
    }
  }

  async #requestSignableMessage(): Promise<string | undefined> {
    if (this.isRetrievingSignableMessage) {
      return;
    }

    try {
      this.isRetrievingSignableMessage = true;
      const publicKey = this.magnet?.web3Account?.address;
      if (!publicKey) {
        Bugsnag.notify(
          'Attempt to request signable message before address was set'
        );
        return;
      }

      // Retrieve a signable message from our server
      let response = await this.#fetchSignableMessage(
        this.requestSignableMessageUrlValue,
        publicKey,
        this.templateInputTarget.value
      );

      if (response.success) {
        return response.message;
      } else {
        await flash(response.message);
        Bugsnag.notify(response.message);
        return;
      }
    } catch (error: any) {
      await flash('Signature failed. Refresh page and try again');
      Bugsnag.notify('Signature failed, signable message not ready');
    } finally {
      this.isRetrievingSignableMessage = false;
    }
  }

  async #fetchSignableMessage(
    url: string,
    publicKey: string,
    messageTemplate: string
  ) {
    let response = await fetch(
      `${url}${publicKey}.json?template=${messageTemplate}`
    );

    let body = await response.json();
    return {
      success: response.ok,
      message: body.message,
    };
  }

  #filloutAndSubmitForm(
    publicKey: string,
    signedMessage: string,
    chainId: string
  ) {
    this.signatureInputTarget.value = signedMessage;
    this.publicKeyInputTarget.value = publicKey;
    this.chainIdInputTarget.value = chainId;
    this.formTarget.submit();
  }

  #setLoadingSignInButton(text = '') {
    this.signInButtonTarget.classList.add('pointer-events-none');
    this.signInButtonTarget.innerHTML =
      '<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 text-foreground">\n' +
      '  <path fill="currentColor" d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z">\n' +
      '    <animateTransform attributeName="transform" type="rotate" dur="0.75s" values="0 12 12;360 12 12" repeatCount="indefinite"></animateTransform>\n' +
      '  </path>\n' +
      '</svg>';
  }

  #enableSignInButton() {
    this.signInButtonTarget.innerHTML =
      this.signInButtonTarget.dataset.defaultText || '';
    this.signInButtonTarget.classList.remove('pointer-events-none');
  }

  disconnectWallet(event: Event) {
    event.preventDefault();
    this.magnet!.disconnect();
  }
}
