import { Controller } from 'stimulus';
import debounce from 'debounce';

export default class extends Controller {
  static values = {
    pageSize: Number,
    recordSetCount: Number,
  };

  // Initialize from recordSetCount, but adjust every time we catch a removal or append event
  totalRowCount = 0;
  isLoadingNextpage = true;

  initialize() {
    // Make sure we dont spam update the pagination link
    // Can consider removing the debounce here or lowering it even further
    // If there's continous streaming updates this will end up never running which is a greater problem
    this.checkAndUpdatePagination = debounce(
      this.checkAndUpdatePagination.bind(this),
      300
    );
    this.totalRowCount = this.recordSetCountValue;
  }

  connect() {
    addEventListener('turbo:before-stream-render', (e) => {
      this.beforeStreamRender(e);
    });
    this.findAndSetBest();

    setTimeout(() => {
      this.checkAndUpdatePagination();
    }, 3000);
  }

  findUnitPricesAndRows() {
    const unitPriceElements =
      this.element.querySelectorAll('[data-unit-price]');
    // Iterate through each element
    let unitPricesAndRows = [];
    unitPriceElements.forEach((element) => {
      // Get the data-unit-price value
      const unitPrice = parseFloat(element.getAttribute('data-unit-price'));

      // Find the closest row (tr) for the current element within the table
      const row = element.closest('tr');

      unitPricesAndRows.push([unitPrice, row]);
    });

    return unitPricesAndRows;
  }

  async beforeStreamRender(event) {
    const newOrderPattern = this.getNewOrderPattern();
    const removeOrderPattern = this.getRemoveOrderPattern();

    if (newOrderPattern.test(event.target.outerHTML)) {
      const defaultAction = event.detail.render;
      event.detail.render = (streamElement) => {
        try {
          if (['prepend'].includes(streamElement.action)) {
            this.totalRowCount += 1;
            this.processStream(streamElement, defaultAction);
            this.checkAndUpdatePagination();
          }
        } catch (error) {
          console.error(error);
        }
      };
    } else if (removeOrderPattern.test(event.target?.attributes[1]?.value)) {
      this.totalRowCount -= 1;
      // Check if the removed row is in the table
      if (this.element.querySelector(`#${event.target.attributes[1].value}`)) {
        const defaultAction = event.detail.render;
        event.detail.render = async (streamElement) => {
          try {
            await defaultAction(streamElement);
            this.findAndSetBest();
          } catch (error) {
            console.error(error);
          }
        };
      }

      this.checkAndUpdatePagination();
    }
  }

  canPlaceRowAtTheEndOfTable(visibleRowCount) {
    return visibleRowCount >= this.totalRowCount;
  }

  calculateNextPageNumber(visibleRowCount) {
    if (visibleRowCount === 0) return 1;

    return Math.floor(visibleRowCount / this.pageSizeValue) + 1;
  }

  checkAndUpdatePagination() {
    if (!this.paginationController) return;

    const nextPageLinkTarget = this.paginationController.nextPageLinkTarget;
    if (!nextPageLinkTarget) return;

    const visibleRowCount = this.findUnitPricesAndRows().length;
    if (visibleRowCount >= this.totalRowCount) {
      // Remove pagination section completely
      this.paginationController.nextSectionTarget.remove();
    } else {
      const url = new URL(nextPageLinkTarget.href);
      const searchParams = new URLSearchParams(url.search);

      const visibleRowCount = this.findUnitPricesAndRows().length;
      const nextPageNumber = this.calculateNextPageNumber(visibleRowCount);
      if (nextPageNumber === searchParams.get('page')) {
        return;
      }

      searchParams.set('page', nextPageNumber);
      nextPageLinkTarget.href = `${url.origin}${
        url.pathname
      }?${searchParams.toString()}`;

      // Trigger next page load if we are close to the end of first page
      if (visibleRowCount < this.pageSizeValue / 2) this.loadNextPage();
    }
  }

  loadNextPage() {
    if (this.isLoadingNextpage) return;

    this.isLoadingNextpage = true;

    // Try to load the next page, and ensure the flag is reset afterward
    this.paginationController
      .loadNextPage()
      .catch((error) => {
        console.error('Error loading next page:', error);
      })
      .finally(() => {
        // Re-check pagination in case we need to trigger another page load immediately
        this.checkAndUpdatePagination();

        setTimeout(() => {
          this.isLoadingNextpage = false;
        }, 3000);
      });
  }

  get paginationController() {
    const paginationElement = this.element.querySelector(
      '[data-controller~="pagination"]'
    );

    return this.application.getControllerForElementAndIdentifier(
      paginationElement,
      'pagination'
    );
  }

  // Abstract methods to be implemented by child classes
  getNewOrderPattern() {
    throw new Error('getNewOrderPattern must be implemented in a subclass');
  }

  getRemoveOrderPattern() {
    throw new Error('getRemoveOrderPattern must be implemented in a subclass');
  }

  processStream(streamElement, defaultAction) {
    throw new Error('processStream must be implemented in a subclass');
  }

  findAndSetBest() {
    throw new Error('findAndSetBest must be implemented in a subclass');
  }
}
